import NavigationBar from "../../components/Navigation/NavigationBar"
import { useLiveSessionStore } from "../../stores/liveSessionStore"

export default function AuthenticatePage() {
  const { url, loading } = useLiveSessionStore()

  return (
    <div className="fade-in h-full flex flex-col">
      <div className="px-6 pt-6">
        <NavigationBar backPage="disclosure" />
      </div>
      <div className="mt-6 w-full h-auto grow">
        {!loading ? (
          <>
            <iframe
              title="authentication portal"
              src={url}
              sandbox="allow-same-origin allow-scripts"
              allow="clipboard-read; clipboard-write"
              className="w-full h-full"
            />
          </>
        ) : (
          <div className="w-full h-full pb-6 px-6">
            <div className="animate-pulse duration-1000 h-full bg-gradient-to-br from-grey-100 to-grey-300 rounded-lg border border-grey-200"></div>
          </div>
        )}
      </div>
    </div>
  )
}
