import { useEffect } from "react"
import { useLinkStore } from "../../stores/linkStore"
import { useLiveSessionStore } from "../../stores/liveSessionStore"
import { usePageRouterStore } from "../../stores/pageRouterStore"

export const useLiveSessionAuth = () => {
  const { setPage } = usePageRouterStore()
  const { linkData, linkToken, setPublicToken } = useLinkStore()
  const { errors, setErrors } = usePageRouterStore()
  const { setLoading, setUrl, setEventSource, establishConnection } = useLiveSessionStore()

  useEffect(() => {
    if (!establishConnection) {
      return
    }
    const eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/v1/link/connect/${linkToken}`)
    setEventSource(eventSource)

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data)
      if (data.message === "pageChange") {
        setLoading(false)
        setUrl(data.url)
      } else if (data.message === "error") {
        setErrors([...errors, { detail: data.error, problem_type: "authentication_error" }])
        setPage("failure")
      } else if (data.message === "authenticating") {
        setLoading(true)
      } else if (data.message === "authenticated") {
        const publicToken = data.publicToken
        setPublicToken(publicToken)
        setPage("success")
      }
    }

    eventSource.onerror = (err) => {
      console.error(err)
      setErrors([...errors, { detail: "Failed to connect to Keet", problem_type: "connection_error" }])
      setPage("failure")
      eventSource.close()
    }

    window.addEventListener("message", (event) => {
      if (event.data === "browserbase-disconnected") {
        setLoading(true)
      }
    })

    return () => {
      eventSource.close()
      window.removeEventListener("message", (event) => {})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkData?.id, linkToken, setErrors, setPage, setPublicToken, establishConnection])
}
