import { FaLink } from "react-icons/fa6"
import { Button } from "../../components/Button/Button"
import Particles from "../../components/Particles/Particles"
import { closeLink } from "../../messages"
import { useLinkStore } from "../../stores/linkStore"
import { usePageRouterStore } from "../../stores/pageRouterStore"

export default function AuthenticatedPage() {
  const { setPage } = usePageRouterStore()
  const { setLinkData, setLinkToken, setPublicToken, linkData } = useLinkStore()

  const close = () => {
    setLinkData(undefined)
    setLinkToken(undefined)
    setPublicToken(undefined)
    setPage("disclosure")
    closeLink()
  }

  return (
    <div className="h-full w-full flex flex-col">
      <div className="grow w-full flex flex-col justify-center items-center">
        <div className="w-24 h-24 bg-gradient-to-br from-blue-200 to-blue-400 border border-blue-200 rounded-2xl z-[100] flex flex-col justify-center items-center shadow-insetMd ">
          <FaLink className="w-14 h-14 text-white filter drop-shadow" />
        </div>
        <div className="text-center z-[100] mt-8 flex flex-col gap-1">
          <h1 className="text-2xl font-medium text-transparent bg-gradient-to-b from-primary-500 to-primary-900 bg-clip-text ">
            Already linked!
          </h1>
          <p className="text-sm text-grey-500 w-[80%] mx-auto">
            You have already linked your {linkData?.integration} account.
          </p>
        </div>
        <Particles color="#3070D0" quantity={100} size={0.3} staticity={90} className="h-full w-full absolute" />
      </div>
      <div className="px-6 pb-6 z-[100]">
        <Button variant="primary" size="lg" width="full" onClick={close}>
          Finish
        </Button>
      </div>
    </div>
  )
}
