import { FaExclamation } from "react-icons/fa6"
import { Button } from "../../components/Button/Button"
import { sendErrors } from "../../messages"
import { useLinkStore } from "../../stores/linkStore"
import { useLiveSessionStore } from "../../stores/liveSessionStore"
import { usePageRouterStore } from "../../stores/pageRouterStore"

export default function FailurePage() {
  const { linkData, setLinkData, setLinkToken, setPublicToken } = useLinkStore()
  const { errors, setPage } = usePageRouterStore()
  const { closeEventSource } = useLiveSessionStore()

  const handleFinish = () => {
    closeEventSource()
    sendErrors(errors)
    setLinkData(undefined)
    setLinkToken(undefined)
    setPublicToken(undefined)
    setPage("disclosure")
  }

  return (
    <div className="h-full w-full flex flex-col">
      <div className="grow w-full flex flex-col justify-center items-center">
        <div className="w-24 h-24 bg-gradient-to-br from-red-200 to-red-400 border border-red-200 rounded-2xl z-[100] flex flex-col justify-center items-center shadow-insetMd ">
          <FaExclamation className="w-11 h-11 text-white filter drop-shadow" />
        </div>
        <div className="text-center z-[100] mt-8 flex flex-col gap-1">
          <h1 className="text-2xl font-medium text-transparent bg-gradient-to-b from-primary-500 to-primary-900 bg-clip-text ">
            Something went wrong
          </h1>
          <p className="text-sm text-grey-500 w-[90%] mx-auto">
            There was an error linking your {linkData?.integration} account. Please try again.
          </p>
        </div>
      </div>
      <div className="px-6 pb-6 z-[100]">
        <Button variant="primary" size="lg" width="full" onClick={handleFinish}>
          Return
        </Button>
      </div>
    </div>
  )
}
