import { ValidationErrors } from "../types"

export function sendLinkFlowReady() {
  window.parent.postMessage(
    {
      messageType: "LINKING_FLOW_READY_FOR_LINK_TOKEN",
    },
    "*",
  )
}

export function sendLinkTokenValid() {
  window.parent.postMessage(
    {
      messageType: "LINK_VALID",
    },
    "*",
  )
}

export function closeLink() {
  window.parent.postMessage(
    {
      messageType: "CLOSE_LINKING_FLOW",
    },
    "*",
  )
}

export function sendErrors(validationErrors: ValidationErrors) {
  window.parent.postMessage(
    {
      messageType: "SEND_VALIDATION_ERRORS_TO_PARENT",
      validationErrors: validationErrors,
    },
    "*",
  )
}
