import { cva, type VariantProps } from "class-variance-authority"
import { forwardRef } from "react"
import { ThreeDots } from "react-loader-spinner"
import { cn } from "../../utils"

export const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-lg flex flex-row gap-3 text-sm font-medium transition-all input-focus",
  {
    variants: {
      variant: {
        primary:
          "hover:bg-gradient-to-b hover:from-primary-600 hover:via-primary-800 hover:to-primary-950 bg-gradient-to-b from-primary-800 via-primary-900 to-primary-950 border border-primary-800 text-white shadow-smInset ease-in-out duration-800 active:outline active:outline-2 active:outline-primary-500/50 active:bg-primary-900 active:from-primary-900 active:via-primary-900 active:to-primary-900 active:shadow-none",
        secondary:
          "bg-white text-grey-800 shadow-sm border border-grey-200 hover:bg-gradient-to-b hover:from-white hover:via-grey-50 hover:to-grey-100 active:outline active:outline-2 active:outline-grey-100 active:border active:border-grey-300 transition-none active:bg-grey-100 active:from-grey-100 active:via-grey-100 active:to-grey-100 active:shadow-none",
        tertiary:
          "bg-transparent border border-transparent hover:bg-grey-100 hover:text-grey-900 text-grey-800 hover:shadow-insetSm hover:border hover:border-grey-300",
        destructive:
          "bg-gradient-to-b from-red-400 to-red-500 text-white shadow-sm border border-red-500 shadow-smInset",
        link: "text-primary-900 underline-offset-4 hover:underline p-0 hover:text-grey-950",
      },
      width: {
        default: "w-auto",
        full: "w-full",
      },
      size: {
        fit: "w-fit h-fit",
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-lg px-8",
        icon: "h-9 w-9",
        iconSm: "h-7 w-7",
        iconLg: "h-10 w-10",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  asChild?: boolean
  loading?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, width, leftIcon, rightIcon, children, loading, asChild = false, ...props }, ref) => {
    return (
      <button
        className={cn(buttonVariants({ variant, size, width, className }))}
        ref={ref}
        {...props}
        onMouseDown={props.onClick}
        onClick={undefined}
      >
        {loading ? (
          <ThreeDots
            visible={true}
            height="24"
            width="24"
            color={variant === "primary" ? "#fff" : "#313149"}
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        ) : (
          <>
            {leftIcon && <span className="mr-3">{leftIcon}</span>}
            {children}
            {rightIcon && <span className="ml-3">{rightIcon}</span>}
          </>
        )}
      </button>
    )
  },
)
Button.displayName = "Button"
