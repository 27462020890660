/// <reference types="react-scripts" />

import { LuLink } from "react-icons/lu"

import { GoArrowSwitch } from "react-icons/go"
import { LiaCircle } from "react-icons/lia"
import { LuShield, LuTrash } from "react-icons/lu"
import { Button } from "../../components/Button/Button"
import NavigationBar from "../../components/Navigation/NavigationBar"

import { useLinkStore } from "../../stores/linkStore"
import { Page, usePageRouterStore } from "../../stores/pageRouterStore"

const Header = () => {
  const { linkData } = useLinkStore()
  return (
    <div className="bg-gradient-to-br from-grey-50 to-grey-200 insetMd border-b border-grey-300 px-6 pt-4 pb-5 shadow-insetSm">
      <NavigationBar />
      <div className="w-full flex flex-row justify-center items-center gap-4">
        {linkData?.companyLogoUri ? (
          <img
            src={linkData?.companyLogoUri}
            className="h-9 animate-in slide-in-from-right duration-500"
            alt="company logo"
          />
        ) : (
          <div className="w-9 h-9 bg-gradient-to-br from-grey-25 to-grey-100 shadow-insetSm rounded-md border border-grey-200 flex flex-col items-center justify-center animate-in slide-in-from-right duration-500">
            <LiaCircle />
          </div>
        )}
        <GoArrowSwitch className="fill-grey-600" />
        <img
          src={linkData?.integrationLogoUri}
          className="h-9 animate-in slide-in-from-left duration-500"
          alt="integration logo"
        />
      </div>
      <div className="mt-6">
        <h1 className="text-xl font-medium bg-gradient-to-b from-primary-700 to-primary-900 text-center leading-[1.25] text-transparent bg-clip-text">
          Connect {linkData?.companyName} to {linkData?.integration}
        </h1>
        <p className="text-xs text-grey-700 text-center mt-2 px-3">
          {linkData?.companyName} uses Keet to securely integrate with your {linkData?.integration} account and perform
          actions on your behalf.
        </p>
      </div>
    </div>
  )
}

const DisclosureItem = ({
  Icon,
  text,
}: {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  text: string
}) => {
  return (
    <li className="flex flex-row items-center gap-3 w-full">
      <div className="w-7 h-7 rounded-md bg-gradient-to-br from-grey-50 to-grey-100 shadow-insetSm border-[0.5px] border-grey-300 grow flex flex-col items-center justify-center">
        <Icon className="stroke-grey-700 w-[0.6rem] h-[0.6rem]" />
      </div>
      <p className="shrink w-full text-sm text-primary-900 leading-[1.25]">{text}</p>
    </li>
  )
}

const Content = () => {
  const { linkData } = useLinkStore()
  return (
    <div className="grow px-6 mt-6 flex flex-col justify-center">
      <ul className="flex flex-col justify-between h-[9.5rem]">
        <DisclosureItem Icon={LuLink} text={`${linkData?.companyName} will have access to your account.`} />
        <DisclosureItem Icon={LuShield} text={`Your authentication info will not be stored.`} />
        <DisclosureItem Icon={LuTrash} text="You can revoke access at any time." />
      </ul>
    </div>
  )
}

export default function DisclosurePage() {
  const { setPage } = usePageRouterStore()
  const { linkData } = useLinkStore()

  const handleContinue = () => {
    const basicAuth = linkData?.basicAuth

    if (basicAuth) {
      setPage(Page.BASIC_AUTH)
    } else {
      setPage(Page.AUTHENTICATE)
    }
  }

  return (
    <div className="fade-in flex flex-col h-full">
      <Header />
      <Content />
      <div className="mt-6 px-6 pb-6">
        <Button variant="primary" size="lg" width="full" onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </div>
  )
}
