import { FaCheck } from "react-icons/fa6"
import { Button } from "../../components/Button/Button"
import Particles from "../../components/Particles/Particles"
import { useLinkStore } from "../../stores/linkStore"
import { useLiveSessionStore } from "../../stores/liveSessionStore"

export default function SuccessPage() {
  const { publicToken, linkData } = useLinkStore()
  const { closeEventSource } = useLiveSessionStore()

  const handleFinish = () => {
    closeEventSource()
    window.parent.postMessage({ messageType: "SEND_PUBLIC_TOKEN_TO_PARENT", publicToken }, "*")
  }

  return (
    <div className="h-full w-full flex flex-col">
      <div className="grow w-full flex flex-col justify-center items-center">
        <div className="w-24 h-24 bg-gradient-to-br from-green-200 to-green-400 border border-green-200 rounded-2xl z-[100] flex flex-col justify-center items-center shadow-insetMd ">
          <FaCheck className="w-14 h-14 text-white filter drop-shadow" />
        </div>
        <div className="text-center z-[100] mt-8 flex flex-col gap-1">
          <h1 className="text-2xl font-medium text-transparent bg-gradient-to-b from-primary-500 to-primary-900 bg-clip-text ">
            Success!
          </h1>
          <p className="text-sm text-grey-500 w-[90%] mx-auto">
            You have successfully linked your {linkData?.integration} account.
          </p>
        </div>
        <Particles color="#47cd89" quantity={100} size={0.3} staticity={90} className="h-full w-full absolute" />
      </div>
      <div className="px-6 pb-6 z-[100]">
        <Button variant="primary" size="lg" width="full" onClick={handleFinish}>
          Finish
        </Button>
      </div>
    </div>
  )
}
