import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { FaLink } from "react-icons/fa6"
import { z } from "zod"
import { Button } from "../../components/Button/Button"
import { Form, FormControl, FormField, FormMessage } from "../../components/Form/Form"
import FormItem from "../../components/Form/FormItem/FormItem"
import { Label } from "../../components/Label/Label"
import NavigationBar from "../../components/Navigation/NavigationBar"
import { TextInput } from "../../components/TextInput/TextInput"
import { useLinkStore } from "../../stores/linkStore"
import { usePageRouterStore } from "../../stores/pageRouterStore"

const schema = z.object({
  code: z.string({ required_error: "Please enter a valid username" }),
})

const defaultValues: TwoFactorFormT = {
  code: "",
}

type TwoFactorFormT = z.infer<typeof schema>

export default function TwoFactorPage() {
  const { linkData, linkToken, setPublicToken } = useLinkStore()
  const { setPage } = usePageRouterStore()
  const [invalidCredentials, setInvalidCredentials] = useState(false)
  const form = useForm<TwoFactorFormT>({
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
  })

  const submit = async (data: TwoFactorFormT) => {
    if (!linkData) {
      throw new Error("No link data")
    }

    const { code } = data

    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/link/mfa`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-link-token": linkToken ? linkToken : "",
      },
      body: JSON.stringify({ code }),
    })
    if (!response.ok) {
      return setInvalidCredentials(true)
    } else {
      setInvalidCredentials(false)
      const data = await response.json()
      setPublicToken(data.publicToken)
      setPage("success")
    }
  }

  return (
    <div className="fade-in h-full flex flex-col px-6 py-6">
      <NavigationBar backPage="disclosure" />

      <div className="mt-6">
        <img
          src={linkData?.integrationLogoUri}
          className="h-9 animate-in slide-in-from-top duration-500"
          alt="integration logo"
        />
        <h1 className="text-lg font-medium mt-4">Link your account</h1>
        <p className="text-grey-500 text-sm">Enter the code that was sent to your primary two factor method.</p>
      </div>
      {invalidCredentials ? (
        <div className="flex flex-row w-full mt-4 rounded-md bg-red-100 border border-red-200 px-4 py-2 items-center justify-center">
          <p className="text-red-500">Invalid credentials</p>
        </div>
      ) : (
        <></>
      )}
      <div className="mt-4 w-full h-auto grow flex flex-col">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) => submit(data))}
            className="flex flex-col h-full grow justify-between"
          >
            <div className="form-elements">
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem>
                    <Label htmlFor="code" required>
                      Code
                    </Label>
                    <FormControl>
                      <TextInput placeholder="Enter your two factor code..." {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="form-actions justify-self-end">
              <Button type="submit" variant="primary" width="full" loading={form.formState.isSubmitting}>
                <FaLink /> Finish linking account
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}
