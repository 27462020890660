import { LinkData } from "@keet-tech/types"
import { create } from "zustand"

interface LinkStoreState {
  linkData: LinkData | undefined
  linkToken: string | undefined
  publicToken: string | undefined
  setLinkData: (link_data: LinkData | undefined) => void
  setLinkToken: (linkToken: string | undefined) => void
  setPublicToken: (publicToken: string | undefined) => void
}

export const useLinkStore = create<LinkStoreState>((set) => ({
  linkToken: undefined,
  linkData: undefined,
  publicToken: undefined,
  setLinkData: (linkData: LinkData | undefined) => set({ linkData }),
  setLinkToken: (linkToken: string | undefined) => set({ linkToken }),
  setPublicToken: (publicToken: string | undefined) => set({ publicToken }),
}))
