import { FaArrowLeft, FaX } from "react-icons/fa6"
import { closeLink } from "../../messages"
import { useLinkStore } from "../../stores/linkStore"
import { useLiveSessionStore } from "../../stores/liveSessionStore"
import { PageT, usePageRouterStore } from "../../stores/pageRouterStore"
import { Button } from "../Button/Button"

export default function NavigationBar({ backPage }: { backPage?: PageT }) {
  const { setPage } = usePageRouterStore()
  const { setLinkData, setLinkToken, setPublicToken } = useLinkStore()
  const { closeEventSource } = useLiveSessionStore()

  const close = () => {
    closeEventSource()
    setLinkData(undefined)
    setLinkToken(undefined)
    setPublicToken(undefined)
    setPage("disclosure")
    closeLink()
  }

  return (
    <div className="w-full flex flex-row justify-between items-center">
      <div>
        {backPage && (
          <Button onClick={() => setPage(backPage)} size={"iconSm"} variant="tertiary">
            <FaArrowLeft className="fill-grey-600" />
          </Button>
        )}
      </div>

      <div className="w-6 h-6">
        <Button onClick={close} size={"iconSm"} variant="tertiary">
          <FaX className="fill-grey-600" />
        </Button>
      </div>
    </div>
  )
}
